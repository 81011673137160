import { colors } from '../../style/variables'
import styled, { css } from 'styled-components'

interface AvatarStyledProps {
    src?: string
    size?: number
    badge?: any
    place?: string
    bgColor?: string
}

export const AvatarEggStyled = styled.div<AvatarStyledProps>`
    position: relative;
    text-decoration: none !important;
    .main {
        border-radius: 100%;
        background: ${({ bgColor }) => (bgColor ? `${bgColor}` : `${colors.lightGreyBg}`)};
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        border: ${({ src }) => (src ? 'none' : `1px solid ${colors.borders}`)};
        width: ${({ size }) => (size ? `${size}px` : '62px')};
        height: ${({ size }) => (size ? `${size}px` : '62px')};
        span.gravatar {
            font-size: ${({ size }) => (size ? size * 0.305 + 'px' : '15px')};
            font-weight: bold;
            letter-spacing: 0.05rem;
            /* color:${colors.washedGreyEgg} */
            color: ${({ bgColor }) => (!bgColor ? '#1F2933' : 'white')};
        }
        ${({ place, size }) =>
            place === 'table' &&
            css`
                width: ${size && '55px'};
                height: ${size && '55px'};
                @media screen and (min-width: 768px) {
                    width: ${size ? `${size}px` : '62px'};
                    height: ${size ? `${size}px` : '62px'};
                }
            `};
        /* ${({ isMe, size }) =>
            isMe &&
            css`
                border: 7px solid ${colors.blueEgg};
                width: ${size ? `${size + 5}px` : '67px'};
                height: ${size ? `${size + 5}px` : '67px'};
            `} */
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
        .default {
            // Chequear esto para tamaños más grandes del avatar
            width: 45%;
            font-size: 3rem;
            color: ${colors.mediumGreyBg};
        }
    }
    .badge {
        position: absolute;
        bottom: -3px;
        right: -3px;
        // badge size is near half the size of the avatar
        height: ${({ size }) => (size ? `${size * 0.52}px` : '24px')};
        width: ${({ size }) => (size ? `${size * 0.52}px` : '24px')};
        // radius should the half of the size
        border-radius: ${({ size }) => (size ? `${size / 2}px` : '26px')};
        display: flex;
        justify-content: center;
        align-items: center;
        // border is around 10% of the avatar size
        border: ${({ size }) => (size ? `${size * 0.08}px` : '2px')} solid white;
        z-index: 4;
        // applies to the badge icon
        .icon {
            font-size: ${({ size }) => (size ? `${size * 0.18}px` : '0.6rem')};
            color: white;
        }
        &.facilitator {
            background-color: ${colors.eggFacilitators};
        }
        &.image-upload {
            background-color: ${colors.darkGreyEgg};
        }
        &.isMentor {
            background-color: ${colors.eggPurple};
        }
        ${props =>
            props.badge &&
            props.badge[2] === 'tutor' &&
            css`
                background-color: white;
            `}
    }
    .voted {
        position: absolute;
        bottom: -5px;
        right: -5px;
        height: ${({ size }) => (size && size * 0.4 > 28 ? `${size * 0.4}px` : '28px')};
        width: ${({ size }) => (size && size * 0.4 > 28 ? `${size * 0.4}px` : '28px')};
        /* background:red; */
        display: flex;
        justify-content: center;
        align-items: center;
        .icon {
            font-size: 1.4rem;
        }
        .icon path {
            color: ${colors.eggColor};
            stroke: white;
            stroke-width: 6rem;
        }
    }
`

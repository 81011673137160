import { createContext } from 'preact'
import { useRef, useContext, useEffect, useState } from 'preact/hooks'
import { io, Socket } from 'socket.io-client'
import { DefaultEventsMap } from 'socket.io-client/build/typed-events'
import { AppContext } from '../app'
import { HelpRequestStatus } from '../interfaces/IWidgetInfo'
import { UIContext } from '../context/UIContext'
import { useHelpRequest } from '../hooks/useHelpRequest'
import { useTimer } from 'react-timer-hook'
import dayjs from 'dayjs'
// const debug = process.env.NODE_ENV === 'development' ? true : false
const debug = true

interface iContext {
    isAvailable: boolean
    emit: (eventName: string, payload?: Record<string, unknown>) => Socket<DefaultEventsMap, DefaultEventsMap>
    volatileEmit: (eventName: string, payload?: Record<string, unknown>) => Socket<DefaultEventsMap, DefaultEventsMap>
}

const Context = createContext<Partial<iContext>>({})
Context.displayName = 'WebSocketContext'

const server: string = process.env.PREACT_APP_WEBSOCKET + '' // 'http://localhost:10000' //

export const SocketContextProvider = ({ children }: { children: any }) => {
    const {
        setRequestNotification,
        setShowFeedback,
        setNotifications,
        setHasNotifications,
        setShowRecognizeMentor,
        dispatchNotification,
    } = useContext(UIContext)
    const {
        widgetInfo,
        fetchInfo,
        fetchTeam,
        updateInfo,
        setMentorVote,
        updateUserOnTable,
        removeUserOnTable,
        infoBlocked,
        updateUserWhenNotified,
    } = useContext(AppContext)
    const { callNewMentor } = useHelpRequest()
    const [, setLastHelpRequestId] = useState('')

    const { mainTimerStart, mainTimerRestart, setMoveMentorToTable } = useContext(UIContext)

    const { updateHelpRequest } = useHelpRequest()

    let sound: any | HTMLAudioElement
    let mentorSelectedSound: HTMLAudioElement | undefined = undefined

    if (typeof window !== 'undefined') {
        if (!sound) {
            sound = new Audio()
        }
    }

    const [courseId, setCourseId] = useState<string | undefined>(undefined)

    const socket = useRef<Socket<DefaultEventsMap, DefaultEventsMap> | null>(null)
    const queryParams = new URLSearchParams(location.search)
    const token = queryParams.get('token')

    const emit = (eventName: string, payload?: Record<string, unknown>) => {
        if (!socket.current) throw '❗ Socket connection not available.'
        return socket.current.emit(eventName, payload)
    }
    const volatileEmit = (eventName: string, payload?: Record<string, unknown>) => {
        if (!socket.current) throw '❗ Socket connection not available.'
        return socket.current.volatile.emit(eventName, payload)
    }

    // When widgetis Ready, setCourseId
    useEffect(() => {
        if (widgetInfo) {
            setCourseId(widgetInfo.team.course._id)
        }
    }, [widgetInfo])

    useEffect(() => {
        if (courseId) {
            if (widgetInfo?.config.algorithmAssignmentSocketAlert) {
                connectSocket()
            } else {
                console.warn('⚠︎ Socket disabled by global config')
            }
        }
    }, [courseId])

    const connectSocket = () => {
        if (!token) return
        if (!widgetInfo) return
        // Create connection
        socket.current = io(server, {
            query: {
                token,
            },
        })

        socket.current.on('connect', () => {
            debug && console.log(`✅ Connecting to socket server ${server} with socketID ${socket.current?.id}`)
            sendEmitEvent()
        })

        socket.current.on('disconnect', () => {
            debug && console.error(`❗ Disconnect from socket server`)
        })

        const getRandomTimeout = () => {
            return Math.floor(Math.random() * (5000 - 1000 + 1)) + 1000
        }

        const shouldShowMentorHelpNotification = () => {
            const me = widgetInfo?.table?.positions.find(item => item._profileId === widgetInfo.me.profile._id)
            if (!me?.isExpertMentor) return true
        }

        var mentorSelected: boolean | undefined
        // On recibe events from server
        socket.current.onAny((eventName: string, payload) => {
            debug && console.info(`Dispatching action ${eventName} with payload:`, payload)
            switch (eventName) {
                case 'ui-team-has-updated':
                    if (payload.payload.meetingSwitchType && payload.payload.table) {
                        dispatchNotification({ type: null })

                        setMoveMentorToTable({
                            showMoveTo: true,
                            payload: {
                                eventName: 'ui-team-has-updated',
                                meetingSwitchType: payload.payload.meetingSwitchType,
                                tableNumber: payload.payload.table,
                                meetingUrl: payload.payload.meetingUrl,
                            },
                        })
                    } else {
                        setMoveMentorToTable({ showMoveTo: false })
                    }
                    payload._algorithmId ? fetchTeam(payload._algorithmId) : fetchInfo()
                    break
                case 'ui-update-view':
                    // Update user view
                    if (infoBlocked) {
                        null
                    } else {
                        setTimeout(() => {
                            console.log('timeout cumplido')
                            fetchInfo?.()
                        }, getRandomTimeout())
                    }
                    // console.log('ui view has updated')
                    break
                case 'students-notifications':
                    setNotifications(current => [payload.payload, ...current])
                    setHasNotifications(true)
                    updateUserWhenNotified()
                    sound.src = '../../assets/audios/melody_Notificaciones.mp3'
                    sound.play()
                    break
                case 'feedback-enabled':
                    setShowFeedback(true)
                    break
                case 'mentor-is-coming':
                    // Start timer. If nobody comes, resend. If someone comes, cancel timer.
                    setLastHelpRequestId(payload.payload._helpRequestId)
                    // mainTimerStart()
                    break
                case 'professor-is-coming':
                    break
                case 'mentor-rejected':
                    mainTimerRestart(dayjs().add(50, 's').toDate(), false) // stops timer to call another mentor
                    callNewMentor(payload.payload._helpRequestId)
                    break
                case 'no-mentor-available':
                    // updateHelpRequest(null)
                    updateHelpRequest({
                        from: '',
                        status: HelpRequestStatus.NO_MENTOR_AVAILABLE,
                        _id: payload.payload._helpRequestId,
                    })
                    setRequestNotification({ status: 'no-mentor-available' })
                    //Show notification.

                    // setTimeout(() => {
                    //     callNewMentor(payload.payload._helpRequestId)
                    // }, 60000)
                    break
                case 'mentor-selected':
                    sound.src = '../../assets/audios/mentor-selected-sound.mp3'
                    sound.play()
                    mentorSelected = true
                    updateHelpRequest({
                        from: '',
                        status: HelpRequestStatus.MENTOR_SELECTED,
                        _id: payload.payload._helpRequestId,
                    })

                    if (window && window.parent) {
                        window.parent.postMessage(
                            {
                                type: 'button-click',
                                message: 'test',
                            },
                            '*'
                        )
                    }
                    break
                case 'mentor-has-joined':
                    // console.log('widgetInfo on socket event', widgetInfo)
                    // Mentor who accepted
                    updateHelpRequest({
                        from: payload.payload.profile._profileId,
                        status: HelpRequestStatus.IN_PROGRESS,
                        _id: payload.payload._helpRequestId,
                    })
                    if (widgetInfo.algorithm?.isMentor && widgetInfo.me.profile._id === payload.payload.profile._profileId) {
                        setMoveMentorToTable({
                            showMoveTo: true,
                            payload: {
                                eventName: 'mentor-has-joined',
                                meetingSwitchType: payload.payload.meetingSwitchType,
                                tableNumber: payload.payload.table,
                                meetingUrl: payload.payload.meetingUrl,
                            },
                        })

                        shouldShowMentorHelpNotification() && setRequestNotification({ status: 'mentor-help', payload })
                        fetchInfo()
                    } else {
                        // if (!widgetInfo.algorithm?.isMentor) {
                        // stops timer started on mentor-is-coming
                        mainTimerRestart(dayjs().add(50, 's').toDate(), false)
                        // }
                        updateUserOnTable({
                            ...payload.payload.profile,
                        })
                        setRequestNotification({ status: 'mentor-joined', payload })
                        sound.src = '../../assets/audios/melody_Mentor_es_asignado_a_un_equipo.mp3'
                        sound.play()
                    }
                    // Student to requested help
                    break
                case 'mentor-has-left':
                    if (widgetInfo.algorithm?.isMentor && widgetInfo.me.profile._id === payload.payload.profile._profileId) {
                        // If i`m mentor, I'm leaving and i'm in another table. Have to fetch original table.
                        fetchInfo()
                    } else {
                        removeUserOnTable(payload.payload.profile._profileId)
                        setRequestNotification(undefined)

                        sound.src = '../../assets/audios/bell_double_down_Mentor_abandona_equipo.mp3'
                        sound.play()

                        if (payload.payload?.mentorToVote) {
                            // The helprequest is updated so as not to lose the message "Ya no hay mentores"
                            // updateInfo({ me: { ...widgetInfo?.me, shouldVote: false } })
                            updateHelpRequest({ from: '', status: HelpRequestStatus.CANCELED, _id: '' })
                            setMentorVote(payload.payload)
                            setShowRecognizeMentor(true)
                            setRequestNotification({ status: 'last-mentor-left' })
                            sound.src = '../../assets/audios/popupAparece_modal_para_reconocer_mentores.mp3'
                            sound.play()
                        }
                    }
                    break
                case 'single-mentor-joined':
                    // If i'm a mentor. Add mentor leaving attended table to join my mentors team back.
                    if (widgetInfo.algorithm?.isMentor) updateUserOnTable(payload.payload.position)
                    break
                case 'single-mentor-left':
                    // If i'm a mentor. Filter my table to delete the mentor that's leaving to answer a helprequest
                    if (widgetInfo.algorithm?.isMentor) {
                        removeUserOnTable(payload.payload.profile._profileId, mentorSelected)
                    }
                    break
                case 'help-request-made':
                    // Event to show other students that a new help request has been made.
                    // Update local helpRequest info to show notification to users.
                    // Avoid refetching /info
                    mainTimerRestart(dayjs().add(50, 's').toDate(), false)
                    updateHelpRequest({ from: payload.from, status: HelpRequestStatus.REQUESTED, _id: payload._id })
                    setRequestNotification({ status: 'requested' })
                    sound.src = '../../assets/audios/alert_3_up_Pedido_de_ayuda_activado.mp3'
                    sound.play()
                    break
                case 'help-request-canceled':
                    // Inform other students that a help request has been canceled.
                    // Actualizar el objeto localmente.
                    mainTimerRestart(dayjs().add(50, 's').toDate(), false)
                    updateHelpRequest({ from: payload.from, status: HelpRequestStatus.CANCELED, _id: payload._id })
                    setRequestNotification({
                        status: payload.status === 'ended' ? 'ended' : 'canceled',
                    })
                    sound.src = '../../assets/audios/alert_3_down_Pedido_ayuda_cancelado.mp3'
                    sound.play()
                    // mentorSelectedSound?.pause()
                    break
                case 'error-on-mentor-automatic-assign':
                    updateHelpRequest(null)
                    setRequestNotification(undefined)
                    break
                default:
                    // Handle unknown action
                    console.error(`❗ Can't dispatch unknown action "${eventName}".`, payload)
                    break
            }
        })

        return () => {
            socket.current?.disconnect()
        }
    }

    const sendEmitEvent = () => {
        emit?.('user-confirm-attendance', {
            _tableNumber: widgetInfo?.table?.tableNumber ?? 0,
            _courseId: widgetInfo?.team.course._id,
        })
        debug && console.log('Emit event confirmed', widgetInfo?.team.course._id)
    }

    return <Context.Provider value={{ emit, volatileEmit }}>{children}</Context.Provider>
}

export const useSocket = () => useContext(Context)

import { AttendanceStatus } from './AttendanceStatus'

export interface IWidgetInfo {
    algorithm?: {
        _id?: string
        isMentor?: boolean
    }
    me: {
        // My info as widget user
        email: string
        lastname: string
        name: string
        own?: {
            attending: AttendanceStatus
        }
        profile: {
            _id: string
        }
        shouldVote: boolean
        stats: {
            assistance: AssistanceType
            cooperationIndex: number
            timesFacilitator: number
            votes: number
        }
        urlImage: string
        // My votes to another user on my own table
        voteData: {
            createdAt?: string
            _profileFromId?: string
            _profileToId?: string
        }
        _id: string
        tutorialWidgetCompleted: boolean
        tutorialPulsesCompleted: boolean
        feedbackCompleted: boolean
        institution: string | null
        shouldGiveFeedback: boolean
        unreadNotifications: boolean
        hasNotifications: boolean
        unreadStories: boolean | undefined
        allReadStories: boolean
        hasStory: boolean
    }
    meeting: IWidgetMeeting
    // The table where I'm sitting on now
    table: IWidgetTable | null
    config: {
        mandatoryVote: boolean
        algorithmAssignmentSocketAlert: boolean
    }
    team: {
        course: {
            _id: string
            name: string
        }
        goal: {
            _id: string
            name: string
        }
        hq: {
            _id: string
            name: string
        }
        _professorScheduleId: string
        nextClass: {
            attendancesRegistrationTime: {
                attendancesEndTime: number
                attendancesStartTime: number
                attendanceDelayTime: number
            }
            day: string
            end: string
            labelDay: string
            start: string
            subject: {
                name: string
            }
        }
        settings: {
            defaultShouldCheckPresents: boolean
            enabledHelpRequest: boolean
            automaticAssignAbsents: boolean
            hideAttendanceStats: boolean
            algorithmType?: HCAType
            widgetFrontCache: boolean
            enabledZoomApp: boolean
            enabledActivationTeam: boolean
        }
        startDate?: {
            date?: string
            enabled?: boolean
        }
        endDate?: {
            date?: string
            enabled?: boolean
        }
    }
}

export interface IWidgetResponse {
    data: IWidgetInfo
}

export type AssistanceType = {
    timesAbsent: number
    timesDelay: number
    timesPresent: number
}

export type TablePos = {
    number: number
    // ProfileId is generally the identification to make api calls
    _profileId: string
    _userId: {
        lastname: string
        name: string
        statusMessage?: string
        urlImage: string
        _id: string
    }
    // Determine if a user is a mentor
    isMentor?: boolean
    isExpertMentor?: boolean
    mentorType?: string
    // Backend exclusive props
    available?: boolean
    content?: number
    description?: string
    hrLevel?: number
    meet_url?: string
}

export type IWidgetTable = {
    // Table facilitator
    facilitator?: string
    helpRequest:
        | {
              from: Partial<TablePos> // Profile ID - TablePos
              status: HelpRequestStatus
              subStatus?: 'mentor-on-way' | 'waiting-another-mentor'
              updatedAt?: string
              _id: string
          }
        | null
        | undefined
    // Students on this table
    positions: TablePos[]
    tableNumber: number
    tableNumberToVote: number
    // Status of my vote to this particular table
    voteData: {
        hasVoted: boolean
        table?: number
        _id?: string
    }
    mentors: TablePos[]
    students: TablePos[]
    isMentorTeam?: boolean
    isExpertMentorTeam?: boolean
}

export type IWidgetMeeting =
    | {
          type: 'zoom' | 'zoom-by-table' | 'zoom-sdk' | 'zoom-sdk-auto'
          urlLink: string
      }
    | EggMeeting
    | null

export interface EggMeeting {
    type: 'egg'
    general: boolean
}

export interface IUserStatsResponse {
    data: {
        assistance: AssistanceType
        connections: {
            lessConnected: Record<string, any>[]
            moreConnected: Record<string, any>[]
        }
        cooperationIndex: number
        givenVotes: number
        performance: {
            performance: PerformanceType[]
        }
        receivedVotes: number
        timesFacilitator: number
        weeklyVariation: number
    }
}

type PerformanceType = {
    day: string
    value: number
}

export type IVoteCandidates = {
    positions: TablePos[]
}

export type HelpModalType =
    | 'request'
    | 'canceled'
    | 'requested-by-me'
    | 'requested'
    | 'mentor-joined'
    | 'mentor-leaving'
    | 'mentor-selected'
    | 'in-progress'
    | 'ended'
    | 'mentor-role-begins'
    | undefined

export enum HelpRequestStatus {
    REQUESTED = 'requested',
    IN_PROGRESS = 'in-progress',
    ENDED = 'ended',
    EXPIRED = 'expired',
    CANCELED = 'canceled',
    MENTOR_JOINED = 'mentor-joined',
    MENTOR_ABOUT_TO_LEAVE = 'mentor-leaving',
    MENTOR_SELECTED = 'mentor-selected',
    LAST_MENTOR_LEFT = 'last-mentor-left',
    REJECTED = 'rejected',
    NO_MENTOR_AVAILABLE = 'no-mentor-available',
}
export interface IUserPatch {
    name?: string
    lastname?: string
    urlImage?: string
}

export enum HCAType {
    HCA1 = 'hca1',
    HCA4 = 'hca4',
    DEFAULT = 'default',
    DIVIDEBYGROUP = 'divideByGroup',
    DEFAULTBYGROUP = 'defaultByGroup',
    REPEATED = 'repeated',
}

export enum UserRole {
    FACILITATOR = 'facilitator',
    STUDENT = 'student',
    MENTOR1 = 'mentor1',
    MENTOR2 = 'mentor2',
    EXPERTMENTOR = 'mentor3',
}

export interface MentorResult {
    courseId: string
    mentorToVote?: TablePos[]
    name: string
    profile: {
        user: Profile
        _profileId: string
    }
}

export interface MentorToVote {
    _id: string
    _userId: Mentor
}

export interface Mentor {
    _id: string
    name: string
    lastname: string
}

export interface Profile {
    email: string
    lastname: string
    name: string
}

import { IWidgetInfo, TablePos } from '../../interfaces/IWidgetInfo'
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'preact/hooks'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AvatarEggStyled } from './AvatarEggStyled'

export type IAvatarEggProps = {
    src?: string
    badge?: any
    title?: string
    facilitator?: boolean
    hasVoted?: boolean
    size?: number
    style?: Record<string, any>
    animate?: boolean
    bgColor?: any
    isMentor?: boolean
    user?: TablePos | undefined
    place?: 'table' | 'list'
} & Partial<IWidgetInfo['me']>

const AvatarEgg = (props: IAvatarEggProps) => {
    const { src, title, name, lastname, urlImage, badge, size, bgColor, place, facilitator, isMentor } = props
    const [source, setSource] = useState<string | undefined>(src ?? urlImage)

    // Update src if src changes
    useEffect(() => {
        setSource(src ?? urlImage)
    }, [src, urlImage])

    return (
        <AvatarEggStyled {...{ src, badge, size, bgColor, place }}>
            <div className="main">
                {!source ? (
                    <span className="gravatar">
                        {(title ?? name)
                            ?.split(' ')
                            .map(word => word.slice(0, 1))
                            .join('')
                            .toUpperCase()
                            .substring(0, 2)}
                    </span>
                ) : (
                    <img src={source} alt={title ?? (name && lastname) ? `${name} ${lastname}` : ''} crossOrigin="anonymous" />
                )}
            </div>
            {facilitator && (
                <div className="badge facilitator">
                    <FontAwesomeIcon className="icon" icon={['fas', 'medal']} />
                </div>
            )}
            {isMentor && (
                <div className="badge isMentor">
                    <FontAwesomeIcon className="icon" icon={['fas', 'graduation-cap']} />
                </div>
            )}
        </AvatarEggStyled>
    )
}

export default AvatarEgg

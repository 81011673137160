import { BtnContainer, BtnIcon } from '../Actions/ActionsBlock'
import { useContext, useState } from 'preact/hooks'
import { AppContext } from '../../app'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Text } from 'preact-i18n'
import Tooltip from '../Tooltip'
import loader from '../../assets/loader.gif'
import styled from 'styled-components'
import { useNavigation } from '../../hooks/useNavigation'

const WaitingArea = () => {
    const { widgetInfo } = useContext(AppContext)
    const { navigation } = useNavigation()
    const { meeting } = widgetInfo ?? {}
    const [showTooltip, setShowTooltip] = useState<boolean>(false)

    const getTextContent = () => {
        switch (meeting?.type) {
            case 'egg': {
                const Waiting = () => (
                    <span>
                        <p style={{ fontWeight: 600 }}>
                            <Text id={'waiting-area.by-table.secondary'}>
                                Una vez que los equipos sean conformados podrás entrar a la sala de equipo.
                            </Text>
                        </p>
                    </span>
                )

                if (meeting?.general) {
                    return (
                        <div>
                            <span>
                                <p style={{ fontWeight: 600 }}>
                                    <Text id={'waiting-area.egg.main'}>¡Quédate con nosotros en el encuentro general!</Text>
                                </p>
                            </span>
                            <Description>
                                <Text id={'waiting-area.egg.secondary'}>En unos minutos conformaremos los equipos y podrás visualizar el tuyo</Text>
                            </Description>
                            <span style={{ marginTop: 20 }}>
                                <img src={loader} height="50" alt="Loading gif" />
                            </span>
                        </div>
                    )
                }

                return <Waiting />
            }
            case 'zoom-sdk':
                return (
                    <div>
                        <span>
                            <p style={{ fontWeight: 600 }}>
                                <Text id={'waiting-area.onboarding.main'}>Por favor, espera unos minutos para que los equipos sean conformados</Text>
                            </p>
                        </span>
                        <Description>
                            <Text id={'waiting-area.onboarding.secondary'}>
                                Mientras tanto puedes acceder al encuentro virtual desde el siguiente botón:
                            </Text>
                        </Description>
                        <BtnContainer
                            onMouseOver={() => setShowTooltip(true)}
                            onMouseLeave={() => setShowTooltip(false)}
                            style={{ display: 'flex', justifyContent: 'center', marginTop: 40 }}
                        >
                            <BtnIcon
                                onClick={() => {
                                    navigation('/')
                                    window.open(`${meeting?.urlLink}`)
                                }}
                                style={{ width: 'auto' }}
                                aria-label="Active Team"
                            >
                                <FontAwesomeIcon className="icon" icon={['fas', 'video']} style={{ marginRight: '7px' }} />
                                Ir al encuentro de inicio
                            </BtnIcon>
                            {showTooltip && <Tooltip content={'Entrar al encuentro'} direction="bottom" delay={100} />}
                        </BtnContainer>

                        <span style={{ marginTop: 20 }}>
                            <img src={loader} height="50" alt="Loading gif" />
                        </span>
                    </div>
                )
            case 'zoom-sdk-auto':
                return (
                    <div>
                        <span>
                            <p style={{ fontWeight: 600 }}>
                                <Text id={'waiting-area.by-table.main'}>Por favor espera unos minutos.</Text>
                            </p>
                        </span>

                        <Description>
                            <Text id={'waiting-area.by-table.secondary'}>
                                Una vez que los equipos sean conformados podrás entrar al encuentro virtual.
                            </Text>
                        </Description>
                        <span>
                            <img src={loader} height="50" alt="Loading gif" />
                        </span>
                    </div>
                )
            default:
                return (
                    <div>
                        <span>
                            <p style={{ fontWeight: 600 }}>
                                <Text id={'waiting-area.by-table.main'}>Por favor espera unos minutos.</Text>
                            </p>
                        </span>

                        <Description>
                            <Text id={'waiting-area.by-table.secondary'}>
                                Una vez que los equipos sean conformados podrás entrar al encuentro virtual.
                            </Text>
                        </Description>
                        <span>
                            <img src={loader} height="50" alt="Loading gif" />
                        </span>
                    </div>
                )
        }
    }

    return <WaitingComponent>{getTextContent()}</WaitingComponent>
}

export default WaitingArea

const WaitingComponent = styled.span`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 40px;
    border-radius: 8px;
    span {
        font-size: 14px;
        margin: 8px 0;
        line-height: 1.4rem;
        display: block;
    }
`

const Description = styled.p`
    color: #638495;
    font-size: 14px;
`

import { IWidgetInfo, IWidgetTable } from '../../interfaces/IWidgetInfo'
import { useContext, useEffect, useState } from 'preact/hooks'
import styled, { css } from 'styled-components'

import { AppContext } from '../../app'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { HCAType } from '../../interfaces/IWidgetInfo'
import { IconHROff } from '../icons/IconHROff'
import { IconHROn } from '../icons/IconHROn'
import ToolTip from '../../components/Tooltip'
import { UIContext } from '../../context/UIContext'
import { WhiteButton } from '../../components/Buttons/WhiteButton'
import { colors } from '../../style/variables'
import { useNavigation } from '../../hooks/useNavigation'
import { useText } from 'preact-i18n'

interface IProps {
    widgetInfo?: IWidgetInfo
    helpRequest: IWidgetTable['helpRequest']
}

const ActionsBlock = ({ widgetInfo, helpRequest }: IProps) => {
    const { setShowActivateTeam, disabledActivateTeam, setDisabledActivateTeam, setRequestModal } = useContext(UIContext)
    const [showTooltip, setShowTooltip] = useState<'table-vote' | 'help-request' | 'join-meeting' | 'active-team' | null>(null)
    const [hideHelpRequestFeature, setHideHelpRequestFeature] = useState(false)
    const [hideActiveTeam, setHideActiveTeam] = useState(false)

    const isZoomSDK = widgetInfo?.meeting?.type === 'zoom-sdk' || widgetInfo?.meeting?.type === 'zoom-sdk-auto' || widgetInfo?.meeting?.type === 'egg'

    const { helpRequestLabel, activateTeam } = useText({
        likeTableLabel: 'actions.labels.likeTeamLabel',
        helpRequestLabel: 'actions.labels.helpRequestLabel',
        onlineMeetingLabel: 'actions.labels.onlineMeetingLabel',
        activateTeam: 'actions.labels.activateTeam',
    })

    useEffect(() => {
        if (disabledActivateTeam) {
            setTimeout(() => {
                setDisabledActivateTeam(false)
            }, 120000)
        }
    }, [disabledActivateTeam])

    useEffect(() => {
        setHideHelpRequestFeature(
            process.env.PREACT_APP_ENABLED_HELP_REQUEST !== 'true' ||
                widgetInfo?.team.settings.hideAttendanceStats === true ||
                widgetInfo?.team.settings.algorithmType !== HCAType.HCA4 ||
                widgetInfo?.team.settings.enabledHelpRequest === false
        )
        setHideActiveTeam(!widgetInfo?.team.settings.enabledActivationTeam === true)
    }, [])

    const getMyPosition = () => {
        return widgetInfo?.table?.positions.findIndex(position => widgetInfo.me.profile._id === position._profileId)
    }

    const setProperRequestModal = () => {
        if (!helpRequest || helpRequest.status === 'ended') {
            setRequestModal('request')
        } else {
            if (helpRequest.status === 'in-progress' && widgetInfo?.algorithm?.isMentor && getMyPosition() === 0) {
                getMyPosition()
                setRequestModal('request')
                return
            }
            if (helpRequest.status === 'no-mentor-available') {
                setRequestModal('request')
                return
            }
            if (helpRequest.status === 'in-progress') {
                setRequestModal('in-progress')
                return
            }
            if (helpRequest.status === 'canceled') {
                setRequestModal('request')
                return
            }
            if (helpRequest.status === 'rejected' || helpRequest.status === 'expired') {
                return
            }
            if (helpRequest.from._profileId === widgetInfo?.me.profile._id) {
                setRequestModal('requested-by-me')
            } else {
                setRequestModal('requested')
            }
        }
    }

    return (
        <ActionsContainer>
            {hideHelpRequestFeature === false && (
                <BtnContainer onMouseOver={() => setShowTooltip('help-request')} onMouseLeave={() => setShowTooltip(null)}>
                    <BtnIcon
                        status={helpRequest?.status}
                        disabled={!widgetInfo?.table}
                        onClick={() => setProperRequestModal()}
                        aria-label="Request help"
                    >
                        {helpRequest?.status === 'requested' ? <IconHROn className="icon" /> : <IconHROff className="icon" />}
                    </BtnIcon>
                    {showTooltip === 'help-request' && <ToolTip content={helpRequestLabel} direction="top" delay={100} />}
                </BtnContainer>
            )}
            {!hideActiveTeam && (
                <BtnContainer onMouseOver={() => setShowTooltip('active-team')} onMouseLeave={() => setShowTooltip(null)}>
                    <BtnIcon disabled={!widgetInfo?.table || disabledActivateTeam} onClick={() => setShowActivateTeam(true)} aria-label="Active Team">
                        {isZoomSDK ? <IconHROff className="icon" /> : <FontAwesomeIcon className="icon" icon={['fas', 'wave-pulse']} />}
                    </BtnIcon>
                    {showTooltip === 'active-team' && <ToolTip content={isZoomSDK ? helpRequestLabel : activateTeam} direction="top" delay={100} />}
                </BtnContainer>
            )}
        </ActionsContainer>
    )
}

export default ActionsBlock

const ActionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    margin-bottom: 10px;
`

export const BtnContainer = styled.div`
    width: 100%;
    position: relative;
`

export const JoinMeetingBtn = styled(WhiteButton)`
    background-color: #ff647c;
    height: 40px;
    box-shadow: 0px 0px 20px #00375014;
    border: 1px solid ${colors.borders};
    border-radius: 12px;
    width: 100%;
    color: white !important;
    &:hover:enabled {
        background-color: #b44758;
        .icon {
            color: white;
        }
    }
    .icon {
        color: white;
    }
`

export const BtnIcon = styled(WhiteButton)`
    background-color: ${({ status }) => (status === 'requested' ? '#00A3FF' : 'none')};
    height: 40px;
    box-shadow: 0px 0px 20px #00375014;
    border: 1px solid ${colors.borders};
    border-radius: 12px;
    width: 100%;
    .icon {
        margin-right: 0;
        font-size: 18px;
        color: ${colors.darkGreyEgg};
    }
    .icon-pulse-svg {
        display: block;
    }
    .icon-pulse-img {
        display: none;
    }
    &:not(:disabled):hover {
        /* background-color: #4c9aff !important; */
        .icon {
            color: #4c9aff;
        }
        .icon-pulse-img {
            display: block;
        }

        .icon-pulse-svg {
            display: none;
        }
    }
    ${({ voted }) =>
        voted &&
        css`
            background: ${colors.darkGreyEgg};
            color: white;
            .icon {
                color: white;
            }

            .icon-pulse-svg {
                display: none;
            }

            .icon-pulse-img {
                display: block;
            }
        `}

    .icon-active {
        color: white;
    }
`

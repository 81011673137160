import Modal from 'react-modal'
import { NotificationType, UIContext } from '../../context/UIContext'
import { useContext } from 'preact/hooks'
import { ModalContainer, modalStyles, NavigationIcon, Paragraph } from './HelpRequestModal'
import { SecondaryTitle } from '../Type/Title'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { WhiteButton } from '../Buttons/WhiteButton'
import { TextButton } from '../Buttons/TextButton'
import axios from 'axios'
import { Text, useText } from 'preact-i18n'
import activateTeamPng from '../../assets/activateTeam/activateTeam.png'
import helpRequestImg from '../../assets/help_request.jpg'
import { AppContext } from '../../app'
import { BlueButton } from '../Buttons/BlueButton'
import { setGAWidgetActivateTeam } from '../../helpers/GAnalytics'

export const ActivateTeamModal = () => {
    const { showActivateTeam, setShowActivateTeam, setDisabledActivateTeam } = useContext(UIContext)
    const { dispatchNotification } = useContext(UIContext)
    const { widgetInfo } = useContext(AppContext)

    const isZoomSDK = widgetInfo?.meeting?.type === 'zoom-sdk' || widgetInfo?.meeting?.type === 'zoom-sdk-auto' || widgetInfo?.meeting?.type === 'egg'

    const { title, message } = useText(
        isZoomSDK
            ? {
                  title: 'activate-team.notifications-hr.title',
                  message: 'activate-team.notifications-hr.subtitle',
              }
            : {
                  title: 'activate-team.notifications.title',
                  message: 'activate-team.notifications.subtitle',
              }
    )

    const handleSendRequetActivatedTeam = async () => {
        try {
            const { data } = await axios.post(`${process.env.PREACT_APP_WIDGET_API}/v1/help-request/activationTeam`, {
                tableNumber: widgetInfo?.table?.tableNumber,
            })

            dispatchNotification({
                type: NotificationType.SUCCESS,
                payload: {
                    title: title,
                    subtitle: message,
                },
            })

            // activated timer for two minute
            setDisabledActivateTeam(true)
        } catch (error) {
            dispatchNotification({
                type: null,
                payload: {
                    title: 'Error',
                    subtitle: 'Failed to upload image. Try again later.',
                },
            })
        }
    }

    return (
        <Modal isOpen={showActivateTeam} style={modalStyles}>
            <ModalContainer>
                <img style={{ width: 108 }} src={isZoomSDK ? activateTeamPng : activateTeamPng} alt="" />
                <SecondaryTitle style={{ marginBottom: '10px', fontSize: 20 }}>
                    <Text id={isZoomSDK ? 'help-request.modal.request.title' : 'activate-team.title'}>Activar equipo</Text>
                </SecondaryTitle>
                <Paragraph style={{ lineHeight: '18px' }}>
                    <Text id={isZoomSDK ? 'help-request.modal.request.desc' : 'activate-team.subtitle'}>
                        Envía una notificación anónima al Coach si quieres que se acerque al equipo para incentivar la participación activa de todos
                        los integrantes.
                    </Text>
                </Paragraph>
                <BlueButton
                    onClick={() => {
                        setGAWidgetActivateTeam()
                        handleSendRequetActivatedTeam()
                        setShowActivateTeam(false)
                    }}
                >
                    <Text id={isZoomSDK ? 'help-request.modal.request.cta' : 'activate-team.buttons.activate'}>Pedir activación</Text>
                </BlueButton>

                <TextButton onClick={() => setShowActivateTeam(false)} style={{ marginBottom: '20px', fontSize: '12px', fontWeight: 600 }}>
                    <Text id={'activate-team.buttons.cancel'}>Cancelar</Text>
                </TextButton>

                <NavigationIcon style={{ marginLeft: 'auto' }} onClick={() => setShowActivateTeam(false)}>
                    <FontAwesomeIcon icon={['fal', 'times']} />
                </NavigationIcon>
            </ModalContainer>
        </Modal>
    )
}

import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faCheckSquare,
    faCoffee,
    faUserCircle,
    faHeart as falHeart,
    faArrowLeft,
    faBell as falBell,
    faMailbox,
    faEgg as falEgg,
    faSpinner,
    faVideo as falVideo,
    faCamera as falCamera,
    faListUl,
    faCheck as falCheck,
    faDrawCircle as falDrawCircle,
    faCircleNotch,
    faTimes,
    faHandPaper,
    faCheckCircle,
    faClock,
    faTimesCircle,
    faLinkSimple,
    faTrash as falTrash,
    faBolt as falBolt,
    faChevronLeft as falChevronLeft,
    faChevronRight as falChevronRight,
    faMapPin as falMapPin
} from '@fortawesome/pro-light-svg-icons'


import {
    faUsersClass,
    faTrophy,
    faEgg as fasEgg,
    faHeart as fasHeart,
    faBell as fasBell,
    faVideo as fasVideo,
    faDrawCircle as fasDrawCircle,
    faCheck as fasCheck,
    faMedal,
    faUserCheck as fasUserCheck,
    faUserSlash as fasUserSlash,
    faUserClock as fasUserClock,
    faUsers,
    faUserHeadset as fasUserHeadset,
    faCamera as fasCamera,
    faCircle,
    faGraduationCap as fasGraduationCap,
    faArrowRightFromBracket,
    faLinkSimple as fasLinkSimple,
    faTrash as fasTrash,
    faBolt as fasBolt,
    faFileCertificate as fasFileCertificate,
    faWavePulse as fasWavePulse,
    faChevronDown as fasChevronDown,
    faCircleVideo as fasCircleVideo,
} from '@fortawesome/pro-solid-svg-icons'

// import { faWavePulse as fasWavePulse } from '@fortawesome/pro-regular-svg-icons'

library.add(
    faCheckSquare,
    faCoffee,
    fasBell,
    falBell,
    faUserCircle,
    falHeart,
    fasHeart,
    faArrowLeft,
    faMailbox,
    faUsersClass,
    faTrophy,
    falEgg,
    fasEgg,
    faSpinner,
    falVideo,
    fasVideo,
    falCamera,
    fasCamera,
    faListUl,
    falDrawCircle,
    fasDrawCircle,
    faCircleNotch,
    fasCheck,
    faMedal,
    fasUserCheck,
    fasUserSlash,
    fasUserClock,
    faUsers,
    faTimes,
    faHandPaper,
    faCheckCircle,
    faClock,
    faTimesCircle,
    fasUserHeadset,
    faCircle,
    fasGraduationCap,
    faArrowRightFromBracket,
    faLinkSimple,
    fasLinkSimple,
    falTrash,
    fasTrash,
    falBolt,
    falBolt,
    fasBolt,
    falCheck,
    fasFileCertificate,
    falChevronLeft,
    falChevronRight,
    fasWavePulse,
    falMapPin,
    fasChevronDown,
    fasCircleVideo
)